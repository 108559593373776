@import "reboot.scss";
@import "accessibility.scss";

:root {
  --bg-level-0: #181a1d;
  --text-contrast-100: #f5f5f5;
  --text-contrast-80: #d7d7d7;
  --text-contrast-60: #8b8b8d;
  --primary: #70e1c8;

  --type-display: "Bricolage Grotesque", "Trebuchet MS", Helvetica, Tahoma,
    sans-serif;
  --type-paragraph: Overpass, "Trebuchet MS", Helvetica, Tahoma, sans-serif;
}

html {
  background-color: var(--bg-level-0);
  color: var(--text-contrast-100);
  font-family: var(--type-paragraph);
}

body {
  --container-width: min(calc(100vw - 32px), 760px);
  padding: calc(6.4rem + 4vh) calc((100vw - var(--container-width)) / 2);
}

caption,
p,
li {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 170%;
}

li::marker {
  color: var(--primary);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}
