*, :before, :after {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  opacity: 1;
  border: 0;
  margin: 8px 0;
}

hr:not([size]) {
  height: 1px;
}

p {
  margin-top: 0;
  margin-bottom: .5rem;
}

abbr[title], abbr[data-bs-original-title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 0;
}

ol, ul, dl {
  margin-top: 0;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 4px;
  margin-left: 0;
}

blockquote {
  margin: 0 0 8px;
}

b, strong {
  font-weight: 700;
}

mark {
  background-color: #fcf8e3;
  padding: .2rem;
}

sub, sup {
  vertical-align: baseline;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

pre, code, kbd, samp {
  direction: ltr ;
  unicode-bidi: bidi-override;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  overflow: auto;
}

pre code {
  color: inherit;
  word-break: normal;
}

code {
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

figure {
  margin: 0 0 8px;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.screenReaderText {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  word-wrap: normal !important;
}

:root {
  --bg-level-0: #181a1d;
  --text-contrast-100: #f5f5f5;
  --text-contrast-80: #d7d7d7;
  --text-contrast-60: #8b8b8d;
  --primary: #70e1c8;
  --type-display: "Bricolage Grotesque", "Trebuchet MS", Helvetica, Tahoma, sans-serif;
  --type-paragraph: Overpass, "Trebuchet MS", Helvetica, Tahoma, sans-serif;
}

html {
  background-color: var(--bg-level-0);
  color: var(--text-contrast-100);
  font-family: var(--type-paragraph);
}

body {
  --container-width: min(calc(100vw - 32px), 760px);
  padding: calc(6.4rem + 4vh) calc((100vw - var(--container-width)) / 2);
}

caption, p, li {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 170%;
}

li::marker {
  color: var(--primary);
}

tbody, td, tfoot, th, thead, tr {
  border: 0 solid;
  border-color: inherit;
}

/*# sourceMappingURL=index.8900bb67.css.map */
